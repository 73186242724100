body {
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: rgba(127, 127, 127, 0.5);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #dedede;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(127, 127, 127, 0.5);
  border-radius: 10px;
}

:root {
  --success-color: #50a850;
  --failure-color: #be6f67;
  --neutral-color: #faf9f6;
}

::-webkit-scrollbar-thumb:hover {
  background: #7f7f7f;
  border-radius: 10px;
}

.textAlign {
  text-align: center;
  font-weight: bold !important;
  background-color: green;
}
