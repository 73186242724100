@font-face {
  font-family: 'Shell Font';
  src: local('Shell Font'), local('ShellFont'),
    url(./assets/fonts/ShellBook.woff2) format('woff2'),
    url(./assets/fonts/ShellBook.woff) format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Shell Font';
  src: local('Shell Font'), local('ShellFont'),
    url(./assets/fonts/ShellBookItalic.woff2) format('woff2'),
    url(./assets/fonts/ShellBookItalic.woff) format('woff');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Shell Font';
  src: local('Shell Font'), local('ShellFont'),
    url(./assets/fonts/ShellMedium.woff2) format('woff2'),
    url(./assets/fonts/ShellMedium.woff) format('woff');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Shell Font';
  src: local('Shell Font'), local('ShellFont'),
    url(./assets/fonts/ShellMediumItalic.woff2) format('woff2'),
    url(./assets/fonts/ShellMediumItalic.woff) format('woff');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'Shell Font';
  src: local('Shell Font'), local('ShellFont'),
    url(./assets/fonts/ShellLight.woff2) format('woff2'),
    url(./assets/fonts/ShellLight.woff) format('woff');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Shell Font';
  src: local('Shell Font'), local('ShellFont'),
    url(./assets/fonts/ShellLightItalic.woff2) format('woff2'),
    url(./assets/fonts/ShellLightItalic.woff) format('woff');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'Shell Font';
  src: local('Shell Font'), local('ShellFont'),
    url(./assets/fonts/ShellHeavy.woff2) format('woff2'),
    url(./assets/fonts/ShellHeavy.woff) format('woff');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Shell Font';
  src: local('Shell Font'), local('ShellFont'),
    url(./assets/fonts/ShellBold.woff2) format('woff2'),
    url(./assets/fonts/ShellBold.woff) format('woff');
  font-style: normal;
  font-weight: bold;
}
.shell-tabs-nav {
  justify-content: flex-start !important;
}
.pointer {
  cursor: pointer;
}

table.pvtTable,
table.pvtUi {
  width: 100%;
  overflow-x: auto;
}
table.pvtUi > tbody > tr > td:first-child {
  width: 200px;
}
.shell-dropdown-overlay {
  z-index: 5;
}
.shell-date-picker-dropdown {
  z-index: 3 !important;
}
.shell-menu-item-content,
.shell-menu-submenu-title > div {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0;
  font-style: normal;
}

.shell-menu li > div {
  padding-right: 7px !important;
}

.large-modal .shell-modal-container {
  width: 800px !important;
  top: 40px !important;
}
.large-modal .shell-modal-container-content {
  min-width: 800px !important;
}

.shell-table-cell,
.shell-select-container-selection-item,
input,
.shell-text-heading strong {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif !important;
}

.dot-bricks {
  position: relative;
  top: 8px;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fbce07;
  color: #fbce07;
  box-shadow: 9991px -16px 0 0 #fbce07, 9991px 0 0 0 #fbce07,
    10007px 0 0 0 #fbce07;
  animation: dot-bricks 2s infinite ease;
}

@keyframes dot-bricks {
  0% {
    box-shadow: 9991px -16px 0 0 #fbce07, 9991px 0 0 0 #fbce07,
      10007px 0 0 0 #fbce07;
  }
  8.333% {
    box-shadow: 10007px -16px 0 0 #fbce07, 9991px 0 0 0 #fbce07,
      10007px 0 0 0 #fbce07;
  }
  16.667% {
    box-shadow: 10007px -16px 0 0 #fbce07, 9991px -16px 0 0 #fbce07,
      10007px 0 0 0 #fbce07;
  }
  25% {
    box-shadow: 10007px -16px 0 0 #fbce07, 9991px -16px 0 0 #fbce07,
      9991px 0 0 0 #fbce07;
  }
  33.333% {
    box-shadow: 10007px 0 0 0 #fbce07, 9991px -16px 0 0 #fbce07,
      9991px 0 0 0 #fbce07;
  }
  41.667% {
    box-shadow: 10007px 0 0 0 #fbce07, 10007px -16px 0 0 #fbce07,
      9991px 0 0 0 #fbce07;
  }
  50% {
    box-shadow: 10007px 0 0 0 #fbce07, 10007px -16px 0 0 #fbce07,
      9991px -16px 0 0 #fbce07;
  }
  58.333% {
    box-shadow: 9991px 0 0 0 #fbce07, 10007px -16px 0 0 #fbce07,
      9991px -16px 0 0 #fbce07;
  }
  66.666% {
    box-shadow: 9991px 0 0 0 #fbce07, 10007px 0 0 0 #fbce07,
      9991px -16px 0 0 #fbce07;
  }
  75% {
    box-shadow: 9991px 0 0 0 #fbce07, 10007px 0 0 0 #fbce07,
      10007px -16px 0 0 #fbce07;
  }
  83.333% {
    box-shadow: 9991px -16px 0 0 #fbce07, 10007px 0 0 0 #fbce07,
      10007px -16px 0 0 #fbce07;
  }
  91.667% {
    box-shadow: 9991px -16px 0 0 #fbce07, 9991px 0 0 0 #fbce07,
      10007px -16px 0 0 #fbce07;
  }
  100% {
    box-shadow: 9991px -16px 0 0 #fbce07, 9991px 0 0 0 #fbce07,
      10007px 0 0 0 #fbce07;
  }
}

/* handled space break for menu items */
.breakOnSpaces > * {
  white-space: break-spaces !important;
}
